import React, { useState } from "react";
import CountrySelector from "./CountrySelector";
import { useUser } from "@clerk/clerk-react";
import FindButton from "./FindButton";
import SearchResults from "../../Layout/ContentTable/SearchResults";
import { ListProvider } from "../../Layout/Context/ListContext";
import ListContainer from "../IntrestList/ListContainer";
import Plan from "../../Layout/Plans/Plan";
import { useAuth } from "@clerk/clerk-react";

const IntrestFinder = () => {
  const { isLoaded, user } = useUser();
  const [interestInput, setInterestInput] = useState("");
  const [results, setResults] = useState(null); // Initialize as null to handle empty state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(""); // State to hold error message
  const { getToken } = useAuth();

  const buttonClicked = async () => {
    if (!interestInput.trim()) {
      setError("Please enter a search term."); // Set an error message
      setResults(null); // Clear previous results
      return;
    }

    setLoading(true);
    setError(""); // Clear any previous error message

    try {
      const template = "interest";
      const userSession = await user.getSessions();
      const sessionId = userSession[0].id;
      console.log(await getToken(sessionId, template));

      const getInterests = async () => {
        const res = await fetch(
          `https://getinterest.rinkusinghdhiman3.workers.dev/?query=${interestInput}`
        );
        const resp = await res.json();
        setLoading(false);
        return resp;
      };

      const fetchedResults = await getInterests();

      if (fetchedResults.length === 0) {
        setError("No response found for the interest."); // Set no results found message
        setResults(null);
      } else {
        setResults(fetchedResults);
      }
    } catch (error) {
      console.error("Error fetching interests:", error);
      setError("Failed to fetch interests. Please try again.");
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      buttonClicked();
    }
  };

  return (
    <div className="bg-white/10 w-full rounded-xl h-full overflow-hidden">
      <div className="w-full md:h-[20%] h-[200px] searchbg z-60">
        <div className="overlay-seach-bg md:h-[100%] h-[200px] flex justify-center items-center">
          <div className="md:h-[50px] flex gap-3 md:flex-row flex-col md:w-auto w-full p-4 md:p-0">
            <input
              className="md:w-[350px] w-full h-full px-5 outline-none border-none rounded-lg shadow-lg py-3 p-0"
              placeholder="Search Interest"
              value={interestInput}
              onChange={(e) => {
                setInterestInput(e.target.value);
                setError(""); // Clear error message on new input
              }}
              onKeyDown={handleKeyDown}
            ></input>
            {/* <CountrySelector /> */}
            <FindButton fn={buttonClicked} />
          </div>
          <div className="absolute top-2 right-2">
            <Plan />
          </div>
        </div>
      </div>
      <ListProvider>
        <div className="p-4 overflow-y-scroll h-[70%]">
          {loading ? (
            <div
              className="animate-spin top-1/2 absolute left-1/2 inline-block size-10 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
              role="status"
              aria-label="loading"
            >
              <span className="sr-only">Loading...</span>
            </div>
          ) : error ? (
            <div className="text-center text-red-500">{error}</div> // Display error or "No response found" message
          ) : results ? (
            <SearchResults results={results} />
          ) : (
            <div className="text-center text-gray-500">
              No response found for the interest. Please enter a keyword.
            </div> // Default empty state message if there are no results
          )}
        </div>
        <ListContainer />
      </ListProvider>
    </div>
  );
};

export default IntrestFinder;
