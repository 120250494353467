import React from "react";
import { Tr, Td } from "react-super-responsive-table";
import ActionButton from "./ActionButton";

const ResultRow = ({ className, id, topic, path, size, name }) => {
  return (
    <>
      <Tr className={`mt-4 border-b border-white/10 ${className}`}>
        <Td className="py-3 px-3">{name}</Td>
        <Td className="py-3 px-3">{size}</Td>
        <Td className="py-3 px-3">{path}</Td>
        <Td className="py-3 px-3">{topic}</Td>
        <Td className="py-3 px-3">
          <ActionButton id={name} />
        </Td>
      </Tr>
    </>
  );
};

export default ResultRow;
