import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ClerkProvider, SignedIn, SignedOut } from "@clerk/clerk-react";
import { Login } from "./Login";
import "./styles.css";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ClerkProvider
      publishableKey="pk_live_Y2xlcmsuaW50ZXJlc3RvcHRpbWl6ZXIuY29tJA"
      supportEmail="support@myzer.ai"
      signInUrl="https://www.interestoptimizer.com/"
      signUpUrl="https://www.interestoptimizer.com/?signup=true"
    >
      <BrowserRouter>
        <SignedIn>
          <App />
        </SignedIn>
        <SignedOut>
          <Login />
        </SignedOut>
      </BrowserRouter>
    </ClerkProvider>
  </React.StrictMode>
);
