import Logo from "../../Utils/Logo";
import SidebarItems from "./SidebarItems";
import UserIcon from "./UserIcon";

const Sidebar = () => {
  return (
    <div className="flex md:h-full h-[70px] mt-2 md:flex-col flex-row gap-3 rounded-3xl bg-white px-2 py-4 justify-between	">
      <div className="flex gap-3 md:flex-col flex-row max-w-[60px]">
        <Logo />
        <div className="border-t border-gray-900/20"> </div>
      </div>
      <SidebarItems />
      <UserIcon />
    </div>
  );
};

export default Sidebar;
