import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import IntrestFinder from "../Features/Intrest/IntrestFinder";
import Sidebar from "./sidebar/Sidebar";
import { useUser } from "@clerk/clerk-react";
import Plan from "./Plans/Plan";
import Savedinterests from "./saved/Savedinterests";

const Layout = () => {
  const { isLoaded, user } = useUser();

  useEffect(() => {
    const handleMetadataChange = () => {
      window.location.reload(); // Reload the entire page
    };

    const metadata = user?.publicMetadata || user?.privateMetadata;
    const metadataString = JSON.stringify(metadata);

    const interval = setInterval(() => {
      const newMetadataString = JSON.stringify(
        user?.publicMetadata || user?.privateMetadata
      );
      if (metadataString !== newMetadataString) {
        handleMetadataChange();
      }
    }, 1000); // Polling every 1 second, adjust as necessary

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [user]);

  const plan = user?.publicMetadata.plan;

  return (
    <div className="flex h-screen md:w-screen md:flex-row flex-col w-full">
      {!plan || plan === "inactive" ? (
        <Plan />
      ) : (
        <>
          <div className="h-content md:mb-4 md:ml-4 md:mt-4 md:w-[50px] w-full ">
            <Sidebar />
          </div>
          <div className="h-screen grow p-4">
            <Routes>
              <Route path="/" element={<IntrestFinder />} />
              <Route path="get-intrests" element={<IntrestFinder />} />
              <Route path="saved-intrests" element={<Savedinterests />} />
            </Routes>
          </div>
        </>
      )}
    </div>
  );
};

export default Layout;
