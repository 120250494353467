import React from "react";
import { useUser, SignOutButton } from "@clerk/clerk-react";

const Plan = () => {
  const { user } = useUser();
  const query = `?first_name=${user.firstName}&last_name=${user.lastName}&email=${user.emailAddresses}`;
  return (
    <div className="p-4 flex justify-center items-center absolute w-screen h-screen">
      <div className="absolute top-3 right-6 text-white px-3 py-2 bg-blue-500 rounded-lg">
        <SignOutButton />
      </div>
      <div class="mx-auto mb-14 text-center">
        <h1 class="text-4xl text-white font-semibold mb-2 lg:text-5xl">
          <span class="text-cyan-300">Flexible</span> Plans
        </h1>
        <p class="text-xl text-gray-100 font-medium mb-8">
          Choose a plan that works best for you and you.
        </p>
        <div class="flex flex-col justify-between items-center lg:flex-row lg:items-start w-[400px]">
          <div class="w-full flex-1 p-8 order-1 shadow-xl rounded-3xl bg-slate-800 text-gray-400 sm:w-96 lg:w-full lg:order-2 lg:mt-0 w-[700px]">
            <div class="mb-8 pb-8 flex items-center border-b border-gray-600">
              <div class="">
                <span class="block text-3xl text-left font-semibold text-white">
                  Lifetime Plan
                </span>
                <span>
                  <span class="font-medium text-xl align-top">₹</span>
                  <span class="text-3xl font-bold text-white">999 </span>
                </span>
                <span class="font-medium">/ One Time</span>
              </div>
            </div>
            <ul class="mb-10 font-medium text-xl">
              <li class="flex text-lg mb-2">
                <img src="https://res.cloudinary.com/williamsondesign/check-grey.svg" />
                <span class="ml-3">Unlimited Research</span>
              </li>
              <li class="flex text-lg mb-2">
                <img src="https://res.cloudinary.com/williamsondesign/check-grey.svg" />
                <span class="ml-3">Save Unlimited Lists</span>
              </li>
              <li class="flex text-lg mb-2">
                <img src="https://res.cloudinary.com/williamsondesign/check-grey.svg" />
                <span class="ml-3">Community Support</span>
              </li>
            </ul>
            <a
              href={`https://payments.pabbly.com/subscribe/66cddf26f235d720475c033b/one-time-offer${query}`}
              class="flex justify-center items-center bg-indigo-600 rounded-xl py-6 px-4 text-center text-white text-2xl"
            >
              Get Lifetime Access
              <img
                src="https://res.cloudinary.com/williamsondesign/arrow-right.svg"
                class="ml-2"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plan;
