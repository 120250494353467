import React, { useEffect, useState } from "react";
import { BookmarkIcon, BookmarkSlashIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { useList } from "../Context/ListContext";

const ActionButton = ({ id }) => {
  const { list, addItem, removeItem, clearList, removeItemBySearch } =
    useList();
  const [addedToList, setAddedToList] = useState(0);

  const resetList = () => {
    setAddedToList(0);
  };
  useEffect(() => {
    if (addedToList) {
      addItem(id);
      console.log(list);
    } else {
      removeItemBySearch(id);
      console.log(list);
    }
  }, [addedToList, id]);

  return (
    <div className="flex flex-row gap-1 group justify-between">
      {!addedToList ? (
        <>
          <button
            className="flex flex-row gap-2"
            onClick={() => {
              setAddedToList(id);
            }}
          >
            <BookmarkIcon className="w-[20px] text-white group-hover:text-pink-300" />
            <span className="text-white group-hover:text-pink-300 text-xs">
              Add to list
            </span>
          </button>
        </>
      ) : (
        <>
          <button
            className="flex flex-row gap-2"
            onClick={() => {
              setAddedToList(0);
            }}
          >
            <BookmarkSlashIcon className="w-[20px] group-hover:text-pink-300 text-red-300" />
            <span className="group-hover:text-pink-300 text-xs text-red-300">
              Remove from list
            </span>
          </button>
          <CheckCircleIcon
            onClick={() => {
              setAddedToList(0);
            }}
            className="w-[20px] text-pink-300 cursor-pointer hover:text-pink-500"
          />
        </>
      )}
    </div>
  );
};

export default ActionButton;
