import { Link } from "react-router-dom";
import logo from "../images/icon.png";

const Logo = () => {
  return (
    <Link className="w-full h-full" to={"/"}>
      <img className="w-full h-full" alt="Myzer Logo" src={logo} />
    </Link>
  );
};

export default Logo;
