import React from "react";

const FindButton = ({ fn }) => {
  return (
    <div className="button-wrapper md:w-[200px] w-[40%]">
      <button
        onClick={() => fn()}
        className="bg-slate-950 h-full md:px-16 w-full py-2 uppercase hover:bg-slate-900  rounded-md text-white text-md font-bold"
      >
        Find
      </button>
    </div>
  );
};

export default FindButton;
