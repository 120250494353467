import { Link } from "react-router-dom";
import Tooltip from "../../Utils/Tooltip";
import { MagnifyingGlassIcon, BookmarkIcon } from "@heroicons/react/20/solid";

const SidebarItems = () => {
  return (
    <div className="flex flex-col gap-6 w-full max-w-[30px] h-full grow items-start">
      <Link to="/get-intrests">
        <Tooltip className="w-full" content="Explore Intrests">
          <div className="sidebar-item-holder flex flex-col items-center bg-slate-50 rounded shadow p-1">
            <MagnifyingGlassIcon className="block md:w-full w-[18px]" />
            <div className="md:hidden block text-xs">Explore</div>
          </div>
        </Tooltip>
      </Link>
      <Link to="/saved-intrests">
        <Tooltip className="w-full" content="Saved">
          <div className="sidebar-item-holder flex flex-col items-center bg-slate-50 rounded shadow p-1">
            <BookmarkIcon className="block md:w-full w-[18px]" />
            <div className="md:hidden block text-xs">Saved</div>
          </div>
        </Tooltip>
      </Link>
    </div>
  );
};

export default SidebarItems;
