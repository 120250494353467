import React, { createContext, useState, useContext } from "react";

const ListContext = createContext();

export const ListProvider = ({ children }) => {
  const [list, setList] = useState([]);

  // Function to add an item to the list
  const addItem = (item) => {
    setList((oldList) => [...oldList, item]);
  };

  // Function to remove an item from the list
  const removeItem = (index) => {
    setList((oldList) => oldList.filter((_, i) => i !== index));
  };

  // Function to clear the list
  const clearList = () => {
    setList([]);
  };

  const removeItemBySearch = (searchTerm) => {
    setList((oldList) => oldList.filter((item) => !item.includes(searchTerm)));
  };

  return (
    <ListContext.Provider
      value={{ list, addItem, removeItem, clearList, removeItemBySearch }}
    >
      {children}
    </ListContext.Provider>
  );
};

export const useList = () => useContext(ListContext);
