import { useState } from "react";
import Layout from "./Layout/Layout";
import { createContext } from "react";

export const UiContext = createContext();

function App() {
  const [globalUi, setGlobalUi] = useState({});
  return (
    <>
      <div class="absolute top-0 z-[-2] h-screen w-screen bg-[#000000] bg-[radial-gradient(#ffffff22_1px,#020617_1px)] bg-[size:20px_20px]"></div>
      <UiContext.Provider value={{ globalUi, setGlobalUi }}>
        <Layout />
      </UiContext.Provider>
    </>
  );
}

export default App;
