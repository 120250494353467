import React, { useState } from "react";
import { useList } from "../../Layout/Context/ListContext";
import { useUser } from "@clerk/clerk-react";

const ListContainer = () => {
  const { list, setList, clearList } = useList();
  const [listTitle, setListTitle] = useState("");
  const { user } = useUser(); // Use Clerk's useUser hook to get the current user

  const handleCopy = () => {
    navigator.clipboard.writeText(list);
  };

  const handleClear = () => {
    if (window.confirm("Are you sure you want to clear the list?")) {
      clearList();
    }
  };

  const handleSave = async () => {
    if (!listTitle || !list || !user?.id) {
      alert("Please enter a list title and ensure you are logged in.");
      return;
    }

    const body = {
      user_id: user.id,
      list_name: listTitle,
      content: list,
    };

    try {
      const response = await fetch(
        "https://api.rinkusinghdhiman3.workers.dev/create-interest-list",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      if (response.ok) {
        alert("List saved successfully!");
        clearList();
        setListTitle("");
      } else {
        alert("Failed to save list. Please try again.");
      }
    } catch (error) {
      console.error("Error saving list:", error);
      alert("An error occurred while saving the list. Please try again.");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSave();
    }
  };

  return (
    <div className="bg-white/10 backdrop-blur py-3 px-5 shadow-md border-slate-950 w-[100%] h-[10%] relative gap-4 flex flex-row items-center">
      <div className="relative w-[40%]">
        <input
          value={list}
          onChange={(e) => setList(e.target.value)}
          disabled
          className="peer/forinputlist bg-gray-800 p-3 w-full border border-white/10 rounded-xl text-white outline-none focus:border-pink-300"
        />
        {list.length > 0 && (
          <div className="absolute right-2 top-1.5 flex gap-2">
            <button
              onClick={handleCopy}
              className="bg-pink-600 text-white font-medium px-3 py-2 rounded-md hover:bg-pink-500"
            >
              COPY
            </button>
            <button
              onClick={handleClear}
              className="bg-gray-600 text-white font-medium px-3 py-2 rounded-md hover:bg-gray-500"
            >
              CLEAR
            </button>
          </div>
        )}
      </div>
      <input
        value={listTitle}
        onChange={(e) => setListTitle(e.target.value)}
        onKeyDown={handleKeyDown} // Add the onKeyDown event handler here
        placeholder="List Title"
        className="bg-gray-800/90 p-3 border border-white/10 rounded-xl text-white w-[20%] outline-none focus:border-pink-300"
      />
      <div className="button-wrapper md:w-[20%] w-[40%]">
        <button
          onClick={handleSave}
          className="bg-pink-300 h-full md:px-16 w-full py-2 uppercase hover:bg-pink-600 rounded-md text-slate-950 hover:text-white text-md font-bold"
        >
          Save List
        </button>
      </div>
    </div>
  );
};

export default ListContainer;
