import React, { useState, useEffect } from "react";
import { useUser } from "@clerk/clerk-react";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";

const SavedInterests = () => {
  const { user } = useUser(); // Get the user information from Clerk
  const [interests, setInterests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [copiedText, setCopiedText] = useState("");

  // Function to fetch interests by user ID
  const fetchInterestsByUserId = async (userId) => {
    try {
      const response = await fetch(
        `https://api.rinkusinghdhiman3.workers.dev/get-lists-by-user?user_id=${user.id}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching interests:", error);
      setError(error);
      return [];
    }
  };

  useEffect(() => {
    const loadInterests = async () => {
      if (!user) {
        setError("User not authenticated");
        setLoading(false);
        return;
      }

      try {
        const data = await fetchInterestsByUserId(user.id); // Use user.id from Clerk
        setInterests(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    loadInterests();
  }, [user]);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setCopiedText(text);
    setShowPopup(true);
    setTimeout(() => setShowPopup(false), 2000); // Hide popup after 2 seconds
  };

  const handleCopyAll = (items) => {
    const text = items.join(", ");
    handleCopy(text);
  };

  if (loading) {
    return (
      <div className="bg-white/10 w-full rounded-xl h-full overflow-hidden">
        <div className="w-full md:h-[10%] h-[100px] z-60">
          <div className="md:h-[100%] h-[200px] flex justify-center items-center bg-slate-900 border-b border-slate-500">
            <h2 className="text-3xl font-medium text-white">Saved Interests</h2>
          </div>
        </div>
        <div className="p-4 text-center">
          <p className="text-xl text-gray-500">Loading your interests...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white/10 w-full rounded-xl h-full overflow-hidden">
        <div className="w-full md:h-[10%] h-[100px] z-60">
          <div className="md:h-[100%] h-[200px] flex justify-center items-center bg-slate-900 border-b border-slate-500">
            <h2 className="text-3xl font-medium text-white">Saved Interests</h2>
          </div>
        </div>
        <div className="p-4 text-center">
          <p className="text-xl text-red-500">
            Error loading interests: {error.message}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white/10 w-full rounded-xl h-full overflow-scroll relative">
      <div className="w-full md:h-[10%] h-[100px] z-60">
        <div className="md:h-[100%] h-[200px] flex justify-center items-center bg-slate-900 border-b border-slate-500">
          <h2 className="text-3xl font-medium text-white">Saved Interests</h2>
        </div>
      </div>
      <div className="p-4">
        {interests.length === 0 ? (
          <div className="text-center">
            <p className="text-xl text-gray-600">No Saved interests found</p>
          </div>
        ) : (
          <ul>
            {interests.map((interest) => (
              <li
                key={interest.id}
                className="mb-4 border-2 border-gray-500 rounded-lg overflow-hidden overlay-seach-bg "
              >
                <div className="bg-gray-900/90 flex gap-2 flex-wrap p-4">
                  {JSON.parse(interest.list_content).map((e, index) => (
                    <div key={index} className="flex items-center gap-2">
                      <div className="bg-slate-950/70 border border-gray-100/30 text-white/90 p-2 rounded">
                        {e}
                        <button
                          onClick={() => handleCopy(e)}
                          className="text-white bg-white/30 px-1 py-1 rounded ml-2 hover:bg-slate-500/10 hover:border hover:border-gray-100/20 border border-gray-100/20"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="size-4"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15.666 3.888A2.25 2.25 0 0 0 13.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 0 1-.75.75H9a.75.75 0 0 1-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 0 1-2.25 2.25H6.75A2.25 2.25 0 0 1 4.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 0 1 1.927-.184"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="p-4 bg-slate-900 border-t border-gray-200/50">
                  <div className="text-xl flex flex-row gap-4 justify-between items-center">
                    <div className="container1">
                      <h3 className="text-white/90 capitalize">
                        {interest.list_name}
                      </h3>
                    </div>
                    <div className="container2 flex gap-3">
                      <button
                        onClick={() =>
                          handleCopyAll(JSON.parse(interest.list_content))
                        }
                        disabled
                        className="text-white text-sm uppercase bg-green-500 px-4 py-2 rounded bg-gradient-to-r from-blue-500 to-blue-600 "
                      >
                        Define Further{" "}
                        <span className="text-xs text-white/40">
                          (⏳ COMING SOON)
                        </span>
                      </button>

                      <button
                        onClick={() =>
                          handleCopyAll(JSON.parse(interest.list_content))
                        }
                        className="text-white text-sm uppercase bg-green-500 px-4 py-2 rounded bg-gradient-to-r from-emerald-500 to-emerald-600 hover:bg-gradient-to-l"
                      >
                        Copy All
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
      {showPopup && (
        <div className="fixed bottom-6 left-1/2 transform -translate-x-1/2 bg-gradient-to-l from-pink-700 to-pink-600 text-white px-4 py-2 rounded shadow-lg z-50">
          ✅ {copiedText} copied to clipboard!
        </div>
      )}
    </div>
  );
};

export default SavedInterests;
