import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { SparklesIcon } from "@heroicons/react/24/outline";
import Tooltip from "../../Utils/Tooltip";
import ActionButton from "./ActionButton";
import ResultRow from "./ResultRow";
import { useList } from "../Context/ListContext";

const SearchResults = ({ results }) => {
  const { list, addItem, removeItem, clearList, removeItemBySearch } =
    useList();

  const [finalData, setFinalData] = useState(results);
  console.log(finalData);

  return (
    <Table>
      <Thead className="bg-white/10 text-md h-[40px] backdrop-blur shadow rounded text-white">
        <Tr className="">
          <Th className="font-medium text-left p-3 w-1/6">Interest</Th>
          <Th className="font-medium text-left p-3  w-1/6">Size</Th>
          <Th className="font-medium text-left p-3  w-1/6">Path</Th>
          <Th className="font-medium text-left p-3  w-1/6">Topic</Th>
          <Th className="font-medium text-left p-3 w-1/6">Action</Th>
        </Tr>
      </Thead>
      <Tbody className="text-white/80 text-sm mt-4">
        {finalData?.map((item, index) =>
          index % 2 === 0 ? (
            <ResultRow
              key={item.id}
              id={item.id}
              name={item.name}
              size={item.size}
              path={item.path}
              topic={item.path}
            />
          ) : (
            <ResultRow
              className="bg-gray-50/5"
              key={item.id}
              id={item.id}
              name={item.name}
              size={item.size}
              path={item.path}
              topic={item.topic}
            />
          )
        )}
      </Tbody>
    </Table>
  );
};

export default SearchResults;
