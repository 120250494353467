import React from "react";
import PropTypes from "prop-types";

const Tooltip = ({ content, position, children, className }) => {
  const getPositionClass = () => {
    switch (position) {
      case "top":
        return "origin-bottom";
      case "right":
        return "origin-left";
      case "bottom":
        return "origin-top";
      case "left":
        return "origin-right";
      default:
        return "origin-bottom";
    }
  };

  return (
    <div className="group relative inline-block">
      <div className={`z-10 ${getPositionClass()} `}>
        {children}
        <div
          className={`z-40 pointer-events-none absolute w-32 rounded-lg bg-gray-900 p-2 text-center text-xs text-white opacity-0 shadow-lg transition-opacity duration-300 group-hover:opacity-100 ${getPositionClass()} scale-0 group-hover:scale-100 border-b-2 border-r-2 shadow`}
        >
          {content}
          <div
            className={`h-3 w-3 ${getPositionClass()} z-6 absolute left-3 top-0 -rotate-45 transform bg-gray-900`}
          />
        </div>
      </div>
    </div>
  );
};

Tooltip.propTypes = {
  content: PropTypes.string.isRequired,
  position: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  children: PropTypes.element.isRequired,
};

Tooltip.defaultProps = {
  position: "bottom",
};

export default Tooltip;
